import {Excel, Sheet} from "@/domain/model/excel/excel.model";
import {CreateMaterial} from "@/domain/command/material/material.command";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useMaterialApi} from "@/domain/service/api/material.api";
import _ from "lodash";
import {Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
// import {findFirstCoordinatesIndexWithKit} from "@/domain/service/importer/kit.importer";
import {useOrderApi, useOrderPlanApi} from "@/domain/service/api/order.api";
import {CreatePendingOrder, CreateOrderPlan} from "@/domain/command/order/order.command";
import moment from "moment";
import {loadingController} from "@ionic/vue";


const findFirstCoordinatesIndexWithKit = (sheet: Sheet): { x: number; y: number } => {

    for (let colIndex = 0; colIndex < sheet.cellMatrix.length; colIndex++) {
        for (let rowIndex = 0; rowIndex < sheet.cellMatrix[colIndex].length; rowIndex++) {
            const cell = sheet.cellMatrix[colIndex][rowIndex];

            if (!cell)
                continue;

            if (!_.isString(cell.value) || cell.value.toLowerCase() !== 'material')
                continue;

            return {x: colIndex, y: rowIndex + 1};
        }
    }

    return {x: -1, y: -1};
}

export function useProductionPlanImporter() {
    const store = useStore();
    const importFromExcel = async (excel: Excel) => {
        const loading = await loadingController
            .create({
                message: 'Cargando excel, esta acción puede tardar algunos minutos...',
                duration: 120000,
            });
        await loading.present();

        const {createOrder, createAllPendingOrders} = useOrderApi();
        const allMaterials = store.getters.allMaterial;
        const {createMaterial} = useMaterialApi();
        const sheet = excel.sheets[0];
        const firstRowWithKit = {x: 0, y: 1};
        const firstRowWithExpectedAmount = {x: 2, y:1};
        const firstRowWithGroup = {x: 1, y:1};
        const allOrders = [];
        for (let index = 0; index < sheet.cellRows.length; index++) {
            const row = sheet.cellRows[index];
            // const index = sheet.cellRows.indexOf(row);
            if (index < firstRowWithKit.y)
                continue;
            if (!row[firstRowWithKit.x])
                continue;
            const ref = row[firstRowWithKit.x].value.toString();
            let expectedAmount = row[firstRowWithExpectedAmount.x].value;
            const group = row[firstRowWithGroup.x].value.toString();


            if(expectedAmount === null)
                expectedAmount = '';

            const material = _.find(allMaterials, (materialNode: Material) => materialNode.ref === ref);
            if(!material)
            {
                const command: CreateMaterial = {
                    id: uuid4(),
                    name: '',
                    ref: ref,
                    type: 'production',
                    components: [],
                    scrapRatio: 0.01,
                    group: group,
                };
                await createMaterial(command);

            }else
            {
                if(material.group === ''){
                    const newMaterial: Material = _.cloneDeep(material);
                    newMaterial.group = group;
                    await useMaterialApi().update(newMaterial);
                }
            }



            const endDate = new Date().setDate(new Date().getDate() + 7);
            const command: CreatePendingOrder = {
                id: uuid4(),
                material: store.getters.materialByRef(ref),
                expectedAmount: expectedAmount,
                receivedDate: 0,
                endDate: Number(moment(endDate).format('X')),
            }
            allOrders.push(command);
            // await createOrder(command);
        }
        await createAllPendingOrders(allOrders);

        await loading.dismiss();


    }

    return {importFromExcel};
}