

import {defineComponent, ref, watch} from "vue";
import {IonIcon, IonText} from "@ionic/vue";
import {cloudUploadOutline} from 'ionicons/icons';
import {useExcelImporter} from "@/app/service/data-importer/excel-importer";
import {useMaterialImporter} from "@/domain/service/importer/material.importer";
import {useKitImporter} from "@/domain/service/importer/kit.importer";
import {useProductionPlanImporter} from "@/domain/service/importer/order.importer";

export default defineComponent({
  name: "OrderListXlsImporter",
  components: {
    IonIcon,
    IonText
  },
  setup() {
    const {importExcel} = useExcelImporter();
    const {importFromExcel} = useProductionPlanImporter();

    const file = ref<File | null>(null);
    const isDragover = ref(false);

    const onInputChange = (event: Event) => {
      const target: any = event.target;
      const files: FileList = target.files;
      file.value = files[0];
    };
    const drop = (event: DragEvent) => {
      event.preventDefault();
      const files = event?.dataTransfer?.files;
      isDragover.value = false;

      if (!files)
        return;

      file.value = files[0];
    };
    const dragover = (event: any) => {
      event.preventDefault();
      isDragover.value = true;
    };
    const dragleave = (event: any) => {
      event.preventDefault();
      isDragover.value = false;
    };

    watch(file, async (newFile: File | null) => {
      const excel = await importExcel(newFile);
      await importFromExcel(excel);
    });

    return {
      cloudUploadOutline,
      isDragover,
      onInputChange,
      dragover,
      dragleave,
      drop,
    };
  }
})
