
import {computed, defineComponent, ref} from "vue";
import OrderListXlsImporter from "@/app/view/order/list/createOrderList/OrderListXlsImporterComponent.vue";
import {
  IonCard, IonCardContent,
  IonCardHeader, IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid, IonHeader,
  IonPage,
  IonRow, IonText, isPlatform
} from "@ionic/vue";
import CreateOrderFormComponent from "@/app/view/order/list/createOrderList/CreateOrderFormComponent.vue";
import OrderListComponent from "@/app/view/order/components/OrderListComponent.vue";
import {Order} from "@/domain/model/order/order.model";
import {useStore} from "vuex";
import {useOrderApi} from "@/domain/service/api/order.api";
import OrderItemListComponent from "@/app/view/order/components/OrderItemListComponent.vue";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useStockApi} from "@/domain/service/api/stock.api";

export default defineComponent({
  name: "AddOrderPlanListPage",
  components: {
    // OrderItemListComponent,
    CreateOrderFormComponent,
    OrderListComponent,
    OrderListXlsImporter,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonText,
    IonHeader,
  },
  setup() {
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const type = ref({type: 'pending'});
    const orders = computed<Order[]>(() => store.getters.allPendingOrders);

    // useMaterialApi().fetchAll();
    // useOrderApi().fetchAll();
    // useStockApi().fetchAll();

    return {
      orders,
      type,
      isTablet,
    };
  }
})
