
import {computed, defineComponent, ref, watch} from "vue";
import {
  IonButton,
  IonCol, IonDatetime, IonInput,
  IonItem,
  IonLabel,
  IonRow, loadingController,
} from "@ionic/vue";
import Autocomplete from "@/app/component/autocomplete/Autocomplete.vue";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import {CreatePendingOrder, emptyCreateOrderCommand} from "@/domain/command/order/order.command";
import router from "@/app/router";
import {useOrderApi} from "@/domain/service/api/order.api";
import Datepicker from 'vue3-datepicker'
import moment from "moment";
import { isPlatform } from '@ionic/vue';
import {formatDate} from "@/domain/helper/date.helper";

export default defineComponent({
  name: "CreateOrderFormComponent",
  components: {
    Autocomplete,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    Datepicker,
    IonDatetime,
  },
  setup() {
    const result = new Date().setDate(new Date().getDate() + 7);
    const picked = ref(new Date(result));
    const pickedFromMobile = ref<string>(formatDate(result/1000, 'YYYY-MM-DD'));
    // const pickedFromMobile = ref<string>(moment().format('YYYY-MM-DD').toString());
    const store = useStore();
    const orderForm = ref<any>(null);
    const {createOrder} = useOrderApi();
    const searchWord = ref<string>('');
    const expectedAmount = ref<any>(null);
    const placeholder = ref<string>('Busque la referencia del Producto...')
    const allMaterial = computed<Material[]>(() => store.getters.allProductionMaterials.map((material: Material) => material.ref));

    const changeFocus = async () => {
      (await expectedAmount.value?.$el.getInputElement()).focus();
      const element = await expectedAmount.value?.$el.getInputElement();
      setTimeout(() => {
        element.select();
      })
    };
    const createOrderCommand = ref<CreatePendingOrder>(emptyCreateOrderCommand());

    watch(pickedFromMobile, () => {
      createOrderCommand.value.endDate = Number(moment(pickedFromMobile.value).format('X'));
    })

    const onSelectElement = (materialRef: string) => {
      const material = store.getters.materialByRef(materialRef);
      createOrderCommand.value.material = {...material};
      searchWord.value = materialRef;
      changeFocus();
    };
    const isSubmitting = ref(false);

    const onCreateOrder = async () => {
      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const loading = await loadingController
          .create({
            message: 'Guardando order',
            duration: 5000,
          });

      await loading.present();
      const newCommand: CreatePendingOrder = {...createOrderCommand.value};
      await createOrder(newCommand);

      await loading.dismiss();
      isSubmitting.value = false;

      searchWord.value = '';
      createOrderCommand.value = emptyCreateOrderCommand();
      picked.value = new Date(result);
      pickedFromMobile.value = formatDate(result/1000, 'YYYY-MM-DD');

      await router.push({path: `/app/planificaciones-produccion/crear-planificacion`})
    }

    watch(picked, () => {
      createOrderCommand.value.endDate = Number(moment(picked.value).format('X'));
    });

    const datepickerFormat = ref<string>('dd-MM-yyyy');

    const isMobile = computed<boolean>(() => isPlatform('mobile'))

    return {
      pickedFromMobile,
      onCreateOrder,
      orderForm,
      expectedAmount,
      allMaterial,
      placeholder,
      searchWord,
      onSelectElement,
      createOrderCommand,
      picked,
      datepickerFormat,
      isMobile,
      // actualYear,
      // maxYear,
    };
  }
})
